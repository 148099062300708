<template>
  <main class="other-auth">
    <transition name="fade" mode="out-in">
      <div class="spinner" v-if="!valid" key="spin">
        <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
      </div>
      <main class="other-auth" v-else key="v">
        <div class="text">
          <i class="fa-solid fa-key fa-4x"></i>
          <span class="title">
            Just one more step!
          </span>
          <span class="subtitle">
            Fill in your new password.
          </span>
        </div>
        <div class="continue">
          <div class="input">
            <i class="fa-solid fa-lock fa-lg"></i>
            <input type="password" name="new_password" id="new_password" placeholder="Your new password" v-model="password">
          </div>
          <div class="input">
            <i class="fa-solid fa-lock fa-lg"></i>
            <input type="password" name="new_password_confirm" placeholder="Confirm your new password" v-model="passwordConfirm">
          </div>
          <a @click="resetPassword">
            <span v-if="!resetLoading">
              Reset password
            </span>
            <span v-if="resetLoading">
              <i class="fa-solid fa-circle-notch fa-spin fa-lg"></i>
            </span>
          </a>
        </div>
      </main>
    </transition>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    valid: null,
    password: "",
    passwordConfirm: "",
    resetLoading: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() {
    let token = this.$route.params.token;
    if (!token) {
      this.$notify({
        type: "error",
        text: "Invalid password reset token!",
      });
      return this.$router.push("/login");
    }

    //Check if token is valid
    axios.get(this.$api("/user/checkToken"), {
      params: { token }
    }).then(() => {
      this.valid = true;
    }).catch(() => {
      this.$notify({
        type: "error",
        text: "Invalid password reset token!",
      });
      this.$router.push("/login");
    });
  },
  methods: {
    resetPassword() {
      if (this.resetLoading) return;
      if (!this.password) {
        this.$notify({
          type: "error",
          text: "Please enter your new password!",
        });
        return;
      }
      if (this.password !== this.passwordConfirm) {
        this.$notify({
          type: "error",
          text: "Your passwords do not match!",
        });
        return;
      }
      this.resetLoading = true;
      axios.post(this.$api("/user/changePassword"), {
        password: this.password,
        password_confirmation: this.passwordConfirm,
        token: this.$route.params.token,
      }).then(() => {
        this.$notify({
          type: "success",
          text: "Your password has been reset!",
        });
        if (this.user) this.$router.push("/settings/account?resetPassword=success")
        else this.$router.push("/login");
      }).catch(() => {
        this.$notify({
          type: "error",
          text: "Something went wrong!",
        });
      });
    },
  },
}
</script>

<style>

</style>